const losses_22_02_25 = {
  personnel: 2800,
  aircrafts: 10,
  helicopters: 7,
  armoredVehicles: 516,
  tanks: 80,
};

const losses_22_02_26 = {
  personnel: 4300,
  aircrafts: 27,
  helicopters: 26,
  armoredVehicles: 706,
  vehicles: 30,
  tanks: 146,
  artillery: 49,
  mlrs: 4,
  cisterns: 60,
  uav: 2,
  vessels: 2,
};

const losses_22_02_27 = {
  personnel: 4300,
  aircrafts: 27,
  helicopters: 26,
  armoredVehicles: 706,
  vehicles: 30,
  tanks: 146,
  artillery: 49,
  mlrs: 4,
  cisterns: 60,
  antiAir: null,
  uav: 2,
  vessels: 2,
};

const losses_22_02_28 = {
  personnel: 5300,
  aircrafts: 29,
  helicopters: 29,
  armoredVehicles: 846,
  vehicles: 291,
  tanks: 198,
  artillery: 74,
  mlrs: 21,
  cisterns: 60,
  antiAir: 5,
  uav: 3,
  vessels: 2,
};

const losses_22_03_01 = {
  personnel: 5710,
  aircrafts: 29,
  helicopters: 29,
  armoredVehicles: 846,
  vehicles: 305,
  tanks: 198,
  artillery: 77,
  mlrs: 24,
  cisterns: 60,
  antiAir: 7,
  uav: 3,
  vessels: 2,
};

const losses_22_03_02 = {
  personnel: 5840,
  aircrafts: 30,
  helicopters: 31,
  armoredVehicles: 862,
  vehicles: 355,
  tanks: 211,
  artillery: 85,
  mlrs: 40,
  cisterns: 60,
  antiAir: 9,
  uav: 3,
  vessels: 2,
};

const losses_22_03_03 = {
  personnel: 9000,
  aircrafts: 30,
  helicopters: 31,
  armoredVehicles: 900,
  vehicles: 374,
  tanks: 217,
  artillery: 90,
  mlrs: 42,
  cisterns: 60,
  antiAir: 11,
  uav: 3,
  vessels: 2,
};

const losses_22_03_04 = {
  personnel: 9166,
  aircrafts: 33,
  helicopters: 37,
  armoredVehicles: 939,
  vehicles: 404,
  tanks: 251,
  artillery: 105,
  mlrs: 50,
  cisterns: 60,
  antiAir: 18,
  uav: 3,
  vessels: 2,
};

const losses_22_03_05 = {
  personnel: 10000,
  aircrafts: 39,
  helicopters: 40,
  armoredVehicles: 945,
  vehicles: 409,
  tanks: 269,
  artillery: 105,
  mlrs: 50,
  cisterns: 60,
  antiAir: 19,
  uav: 3,
  vessels: 2,
};

const losses_22_03_06 = {
  personnel: 11000,
  aircrafts: 44,
  helicopters: 48,
  armoredVehicles: 985,
  vehicles: 447,
  tanks: 285,
  artillery: 109,
  mlrs: 50,
  cisterns: 60,
  antiAir: 21,
  uav: 4,
  vessels: 2,
};

const losses_22_03_07 = {
  personnel: 11000,
  aircrafts: 46,
  helicopters: 68,
  armoredVehicles: 999,
  vehicles: 454,
  tanks: 290,
  artillery: 117,
  mlrs: 50,
  cisterns: 60,
  antiAir: 23,
  uav: 7,
  vessels: 3,
};

const losses_22_03_08 = {
  personnel: 12000,
  aircrafts: 48,
  helicopters: 80,
  armoredVehicles: 1036,
  vehicles: 474,
  tanks: 303,
  artillery: 120,
  mlrs: 56,
  cisterns: 60,
  antiAir: 27,
  uav: 7,
  vessels: 3,
};

const losses_22_03_09 = {
  personnel: 12000,
  aircrafts: 49,
  helicopters: 81,
  armoredVehicles: 1070,
  vehicles: 482,
  tanks: 317,
  artillery: 120,
  mlrs: 56,
  cisterns: 60,
  antiAir: 28,
  uav: 7,
  vessels: 3,
};

const losses_22_03_10 = {
  personnel: 12000,
  aircrafts: 49,
  helicopters: 81,
  armoredVehicles: 1105,
  vehicles: 526,
  tanks: 335,
  artillery: 123,
  mlrs: 56,
  cisterns: 60,
  antiAir: 29,
  uav: 7,
  vessels: 3,
};

const losses_22_03_11 = {
  personnel: 12000,
  aircrafts: 57,
  helicopters: 83,
  armoredVehicles: 1165,
  vehicles: 558,
  tanks: 353,
  artillery: 125,
  mlrs: 58,
  cisterns: 60,
  antiAir: 31,
  uav: 7,
  vessels: 3,
};

const losses_22_03_12 = {
  personnel: 12000,
  aircrafts: 58,
  helicopters: 83,
  armoredVehicles: 1205,
  vehicles: 585,
  tanks: 362,
  artillery: 135,
  mlrs: 62,
  cisterns: 60,
  antiAir: 33,
  uav: 7,
  vessels: 3,
};

const losses_22_03_13 = {
  personnel: 12000,
  aircrafts: 74,
  helicopters: 86,
  armoredVehicles: 1226,
  vehicles: 600,
  tanks: 374,
  artillery: 140,
  mlrs: 62,
  cisterns: 60,
  antiAir: 34,
  uav: 7,
  vessels: 3,
};

const losses_22_03_14 = {
  personnel: 12000,
  aircrafts: 77,
  helicopters: 90,
  armoredVehicles: 1249,
  vehicles: 617,
  tanks: 389,
  artillery: 150,
  mlrs: 64,
  cisterns: 60,
  antiAir: 34,
  uav: 8,
  vessels: 3,
};

const losses_22_03_15 = {
  personnel: 13500,
  aircrafts: 81,
  helicopters: 95,
  armoredVehicles: 1279,
  vehicles: 640,
  tanks: 404,
  artillery: 150,
  mlrs: 64,
  cisterns: 60,
  antiAir: 36,
  uav: 9,
  vessels: 3,
};

const losses_22_03_16 = {
  personnel: 13800,
  aircrafts: 84,
  helicopters: 108,
  armoredVehicles: 1375,
  vehicles: 819,
  tanks: 430,
  artillery: 190,
  mlrs: 70,
  cisterns: 60,
  antiAir: 43,
  uav: 11,
  vessels: 3,
  specialVehicle: 10,
};

const losses_22_03_17 = {
  personnel: 14000,
  aircrafts: 86,
  helicopters: 108,
  armoredVehicles: 1435,
  vehicles: 864,
  tanks: 444,
  artillery: 201,
  mlrs: 72,
  cisterns: 60,
  antiAir: 43,
  uav: 11,
  vessels: 3,
  specialVehicle: 10,
};

const losses_22_03_18 = {
  personnel: 14200,
  aircrafts: 93,
  helicopters: 112,
  armoredVehicles: 1448,
  vehicles: 879,
  tanks: 450,
  artillery: 205,
  mlrs: 72,
  cisterns: 60,
  antiAir: 43,
  uav: 12,
  vessels: 3,
  specialVehicle: 11,
};

const losses_22_03_19 = {
  personnel: 14400,
  aircrafts: 95,
  helicopters: 115,
  armoredVehicles: 1470,
  vehicles: 914,
  tanks: 466,
  artillery: 213,
  mlrs: 72,
  cisterns: 60,
  antiAir: 44,
  uav: 17,
  vessels: 3,
  specialVehicle: 11,
};

const losses_22_03_20 = {
  personnel: 14700,
  aircrafts: 96,
  helicopters: 118,
  armoredVehicles: 1487,
  vehicles: 947,
  tanks: 476,
  artillery: 230,
  mlrs: 74,
  cisterns: 60,
  antiAir: 44,
  uav: 21,
  vessels: 3,
  specialVehicle: 12,
};

const losses_22_03_21 = {
  personnel: 15000,
  aircrafts: 97,
  helicopters: 121,
  armoredVehicles: 1535,
  vehicles: 969,
  tanks: 498,
  artillery: 240,
  mlrs: 80,
  cisterns: 60,
  antiAir: 45,
  uav: 24,
  vessels: 3,
  specialVehicle: 13,
};

const losses_22_03_22 = {
  personnel: 15300,
  aircrafts: 99,
  helicopters: 123,
  armoredVehicles: 1556,
  vehicles: 1000,
  tanks: 509,
  artillery: 252,
  mlrs: 80,
  cisterns: 70,
  antiAir: 45,
  uav: 35,
  vessels: 3,
  specialVehicle: 15,
};

const losses_22_03_23 = {
  personnel: 15600,
  aircrafts: 101,
  helicopters: 124,
  armoredVehicles: 1578,
  vehicles: 1008,
  tanks: 517,
  artillery: 267,
  mlrs: 80,
  cisterns: 70,
  antiAir: 47,
  uav: 42,
  vessels: 4,
  specialVehicle: 15,
};

const losses_22_03_24 = {
  personnel: 15800,
  aircrafts: 108,
  helicopters: 124,
  armoredVehicles: 1597,
  vehicles: 1033,
  tanks: 530,
  artillery: 280,
  mlrs: 82,
  cisterns: 72,
  antiAir: 47,
  uav: 50,
  vessels: 4,
  specialVehicle: 16,
};

const losses_22_03_25 = {
  personnel: 16100,
  aircrafts: 115,
  helicopters: 125,
  armoredVehicles: 1625,
  vehicles: 1089,
  tanks: 561,
  artillery: 291,
  mlrs: 90,
  cisterns: 72,
  antiAir: 49,
  uav: 53,
  vessels: 5,
  specialVehicle: 18,
};

const losses_22_03_26 = {
  personnel: 16400,
  aircrafts: 117,
  helicopters: 127,
  armoredVehicles: 1640,
  vehicles: 1131,
  tanks: 575,
  artillery: 293,
  mlrs: 91,
  cisterns: 73,
  antiAir: 51,
  uav: 56,
  vessels: 7,
  specialVehicle: 19,
  srmb: 2,
};

const losses_22_03_27 = {
  personnel: 16600,
  aircrafts: 121,
  helicopters: 127,
  armoredVehicles: 1664,
  vehicles: 1144,
  tanks: 582,
  artillery: 294,
  mlrs: 93,
  cisterns: 73,
  antiAir: 52,
  uav: 56,
  vessels: 7,
  specialVehicle: 21,
  srmb: 4,
};

const losses_22_03_28 = {
  personnel: 17000,
  aircrafts: 123,
  helicopters: 127,
  armoredVehicles: 1694,
  vehicles: 1150,
  tanks: 586,
  artillery: 302,
  mlrs: 95,
  cisterns: 73,
  antiAir: 54,
  uav: 66,
  vessels: 7,
  specialVehicle: 21,
  srmb: 4,
};

const losses_22_03_29 = {
  personnel: 17200,
  aircrafts: 127,
  helicopters: 129,
  armoredVehicles: 1710,
  vehicles: 1178,
  tanks: 597,
  artillery: 303,
  mlrs: 96,
  cisterns: 73,
  antiAir: 54,
  uav: 71,
  vessels: 7,
  specialVehicle: 21,
  srmb: 4,
};

const losses_22_03_30 = {
  personnel: 17300,
  aircrafts: 131,
  helicopters: 131,
  armoredVehicles: 1723,
  vehicles: 1184,
  tanks: 605,
  artillery: 305,
  mlrs: 96,
  cisterns: 75,
  antiAir: 54,
  uav: 81,
  vessels: 7,
  specialVehicle: 21,
  srmb: 4,
};

const losses_22_03_31 = {
  personnel: 17500,
  aircrafts: 135,
  helicopters: 131,
  armoredVehicles: 1735,
  vehicles: 1201,
  tanks: 614,
  artillery: 311,
  mlrs: 96,
  cisterns: 75,
  antiAir: 54,
  uav: 83,
  vessels: 7,
  specialVehicle: 22,
  srmb: 4,
};

const losses_22_04_01 = {
  personnel: 17700,
  aircrafts: 143,
  helicopters: 131,
  armoredVehicles: 1751,
  vehicles: 1220,
  tanks: 625,
  artillery: 316,
  mlrs: 96,
  cisterns: 76,
  antiAir: 54,
  uav: 85,
  vessels: 7,
  specialVehicle: 24,
  srmb: 4,
};

const losses_22_04_02 = {
  personnel: 17800,
  aircrafts: 143,
  helicopters: 134,
  armoredVehicles: 1776,
  vehicles: 1236,
  tanks: 631,
  artillery: 317,
  mlrs: 100,
  cisterns: 76,
  antiAir: 54,
  uav: 87,
  vessels: 7,
  specialVehicle: 24,
  srmb: 4,
};

const losses_22_04_03 = {
  personnel: 18000,
  aircrafts: 143,
  helicopters: 134,
  armoredVehicles: 1830,
  vehicles: 1249,
  tanks: 644,
  artillery: 325,
  mlrs: 105,
  cisterns: 76,
  antiAir: 54,
  uav: 89,
  vessels: 7,
  specialVehicle: 24,
  srmb: 4,
};

const losses_22_04_04 = {
  personnel: 18300,
  aircrafts: 147,
  helicopters: 134,
  armoredVehicles: 1844,
  vehicles: 1273,
  tanks: 647,
  artillery: 330,
  mlrs: 107,
  cisterns: 76,
  antiAir: 54,
  uav: 92,
  vessels: 7,
  specialVehicle: 25,
  srmb: 4,
};

const losses_22_04_05 = {
  personnel: 18500,
  aircrafts: 150,
  helicopters: 134,
  armoredVehicles: 1858,
  vehicles: 1322,
  tanks: 676,
  artillery: 332,
  mlrs: 107,
  cisterns: 76,
  antiAir: 55,
  uav: 94,
  vessels: 7,
  specialVehicle: 25,
  srmb: 4,
};

const losses_22_04_06 = {
  personnel: 18600,
  aircrafts: 150,
  helicopters: 135,
  armoredVehicles: 1861,
  vehicles: 1324,
  tanks: 684,
  artillery: 332,
  mlrs: 107,
  cisterns: 76,
  antiAir: 55,
  uav: 96,
  vessels: 7,
  specialVehicle: 25,
  srmb: 4,
};

const losses_22_04_07 = {
  personnel: 18900,
  aircrafts: 150,
  helicopters: 135,
  armoredVehicles: 1891,
  vehicles: 1358,
  tanks: 698,
  artillery: 332,
  mlrs: 108,
  cisterns: 76,
  antiAir: 55,
  uav: 111,
  vessels: 7,
  specialVehicle: 25,
  srmb: 4,
};

const losses_22_04_08 = {
  personnel: 19000,
  aircrafts: 150,
  helicopters: 135,
  armoredVehicles: 1891,
  vehicles: 1361,
  tanks: 700,
  artillery: 333,
  mlrs: 108,
  cisterns: 76,
  antiAir: 55,
  uav: 112,
  vessels: 7,
  specialVehicle: 25,
  srmb: 4,
};

const losses_22_04_09 = {
  personnel: 19100,
  aircrafts: 151,
  helicopters: 136,
  armoredVehicles: 1895,
  vehicles: 1363,
  tanks: 705,
  artillery: 335,
  mlrs: 108,
  cisterns: 76,
  antiAir: 55,
  uav: 112,
  vessels: 7,
  specialVehicle: 25,
  srmb: 4,
};

const losses_22_04_10 = {
  personnel: 19300,
  aircrafts: 152,
  helicopters: 137,
  armoredVehicles: 1911,
  vehicles: 1384,
  tanks: 722,
  artillery: 342,
  mlrs: 108,
  cisterns: 76,
  antiAir: 55,
  uav: 112,
  vessels: 7,
  specialVehicle: 25,
  srmb: 4,
};

const losses_22_04_11 = {
  personnel: 19500,
  aircrafts: 154,
  helicopters: 137,
  armoredVehicles: 1923,
  vehicles: 1387,
  tanks: 725,
  artillery: 347,
  mlrs: 111,
  cisterns: 76,
  antiAir: 55,
  uav: 119,
  vessels: 7,
  specialVehicle: 25,
  srmb: 4,
};

const losses_22_04_12 = {
  personnel: 19600,
  aircrafts: 157,
  helicopters: 140,
  armoredVehicles: 1946,
  vehicles: 1406,
  tanks: 732,
  artillery: 349,
  mlrs: 111,
  cisterns: 76,
  antiAir: 63,
  uav: 124,
  vessels: 7,
  specialVehicle: 25,
  srmb: 4,
};

const losses_22_04_13 = {
  personnel: 19800,
  aircrafts: 158,
  helicopters: 143,
  armoredVehicles: 1964,
  vehicles: 1429,
  tanks: 739,
  artillery: 358,
  mlrs: 115,
  cisterns: 76,
  antiAir: 64,
  uav: 132,
  vessels: 7,
  specialVehicle: 25,
  srmb: 4,
};

const losses_22_04_14 = {
  personnel: 19900,
  aircrafts: 160,
  helicopters: 144,
  armoredVehicles: 1968,
  vehicles: 1437,
  tanks: 753,
  artillery: 366,
  mlrs: 122,
  cisterns: 76,
  antiAir: 64,
  uav: 134,
  vessels: 7,
  specialVehicle: 25,
  srmb: 4,
};

const losses_22_04_15 = {
  personnel: 20000,
  aircrafts: 163,
  helicopters: 144,
  armoredVehicles: 1976,
  vehicles: 1443,
  tanks: 756,
  artillery: 366,
  mlrs: 122,
  cisterns: 76,
  antiAir: 66,
  uav: 135,
  vessels: 8,
  specialVehicle: 25,
  srmb: 4,
};

const losses_22_04_16 = {
  personnel: 20100,
  aircrafts: 163,
  helicopters: 145,
  armoredVehicles: 1982,
  vehicles: 1458,
  tanks: 762,
  artillery: 371,
  mlrs: 125,
  cisterns: 76,
  antiAir: 66,
  uav: 138,
  vessels: 8,
  specialVehicle: 26,
  srmb: 4,
};

const losses_22_04_17 = {
  personnel: 20300,
  aircrafts: 165,
  helicopters: 146,
  armoredVehicles: 2002,
  vehicles: 1471,
  tanks: 773,
  artillery: 376,
  mlrs: 127,
  cisterns: 76,
  antiAir: 66,
  uav: 148,
  vessels: 8,
  specialVehicle: 27,
  srmb: 4,
};

const losses_22_04_18 = {
  personnel: 20600,
  aircrafts: 167,
  helicopters: 147,
  armoredVehicles: 2041,
  vehicles: 1487,
  tanks: 790,
  artillery: 381,
  mlrs: 130,
  cisterns: 76,
  antiAir: 67,
  uav: 155,
  vessels: 8,
  specialVehicle: 27,
  srmb: 4,
};

const losses_22_04_19 = {
  personnel: 20800,
  aircrafts: 169,
  helicopters: 150,
  armoredVehicles: 2063,
  vehicles: 1495,
  tanks: 802,
  artillery: 386,
  mlrs: 132,
  cisterns: 76,
  antiAir: 67,
  uav: 158,
  vessels: 8,
  specialVehicle: 27,
  srmb: 4,
};

export default {
  '2022-02-25': losses_22_02_25,
  '2022-02-26': losses_22_02_26,
  '2022-02-27': losses_22_02_27,
  '2022-02-28': losses_22_02_28,
  '2022-03-01': losses_22_03_01,
  '2022-03-02': losses_22_03_02,
  '2022-03-03': losses_22_03_03,
  '2022-03-04': losses_22_03_04,
  '2022-03-05': losses_22_03_05,
  '2022-03-06': losses_22_03_06,
  '2022-03-07': losses_22_03_07,
  '2022-03-08': losses_22_03_08,
  '2022-03-09': losses_22_03_09,
  '2022-03-10': losses_22_03_10,
  '2022-03-11': losses_22_03_11,
  '2022-03-12': losses_22_03_12,
  '2022-03-13': losses_22_03_13,
  '2022-03-14': losses_22_03_14,
  '2022-03-15': losses_22_03_15,
  '2022-03-16': losses_22_03_16,
  '2022-03-17': losses_22_03_17,
  '2022-03-18': losses_22_03_18,
  '2022-03-19': losses_22_03_19,
  '2022-03-20': losses_22_03_20,
  '2022-03-21': losses_22_03_21,
  '2022-03-22': losses_22_03_22,
  '2022-03-23': losses_22_03_23,
  '2022-03-24': losses_22_03_24,
  '2022-03-25': losses_22_03_25,
  '2022-03-26': losses_22_03_26,
  '2022-03-27': losses_22_03_27,
  '2022-03-28': losses_22_03_28,
  '2022-03-29': losses_22_03_29,
  '2022-03-30': losses_22_03_30,
  '2022-03-31': losses_22_03_31,
  '2022-04-01': losses_22_04_01,
  '2022-04-02': losses_22_04_02,
  '2022-04-03': losses_22_04_03,
  '2022-04-04': losses_22_04_04,
  '2022-04-05': losses_22_04_05,
  '2022-04-06': losses_22_04_06,
  '2022-04-07': losses_22_04_07,
  '2022-04-08': losses_22_04_08,
  '2022-04-09': losses_22_04_09,
  '2022-04-10': losses_22_04_10,
  '2022-04-11': losses_22_04_11,
  '2022-04-12': losses_22_04_12,
  '2022-04-13': losses_22_04_13,
  '2022-04-14': losses_22_04_14,
  '2022-04-15': losses_22_04_15,
  '2022-04-16': losses_22_04_16,
  '2022-04-17': losses_22_04_17,
  '2022-04-18': losses_22_04_18,
  '2022-04-19': losses_22_04_19,
};
