export default {
  eng: {
    nav: {
      losses: 'Home page',
      donate: 'How can I help?',
      charts: 'Charts',
      screenshot: 'Download infographic',
    },
    main: {
      header: {
        header: 'Losses of russian invaders in Ukraine',
        updateDate: 'Last updated:',
        language: 'en-us',
        numbersProvided: 'Numbers provided by the ',
        genStaff: 'General Staff of the AFU',
        warning: 'Some numbers are approximate and cannot be verified due to the high intensity of combat actions',
      },
      dates: {
        start: 'Start of the full-scale aggression',
        language: 'en-us',
        daysPassed: 'days of the russian full-scale aggression',
        endOfWar: 'End of the war',
        today: 'Today',
      },
      losses: {
        personnel: 'soldiers',
        aircrafts: 'fighter jets',
        helicopters: 'helicopters',
        armoredVehicles: 'armored vehicles',
        vehicles: 'vehicles',
        tanks: 'tanks',
        artillery: 'artillery pieces',
        mlrs: 'multiple launch rocket systems',
        cisterns: 'fuel cisterns',
        antiAir: 'anti-aircraft warfare systems',
        uav: 'UAV operational-tactical level',
        vessels: 'vessels',
        specialVehicle: 'special equipment',
        srmb: 'mobile SRMB system',
      },
    },
    donate: {
      copyIconTitle: 'Copy text',
      expandBtn: {
        open: 'Open section',
        close: 'Close section',
      },
      military: {
        header: 'Supporting Defenders of Ukraine',
        comeBackAlive: {
          orgName: 'Come Back Alive Fund.',
          donationsOutside: {
            header: 'Donations from abroad:',
            fondy: {
              header: 'Donate via Fondy.eu',
              text: 'Fondy has the highest security rating – PCI DSS Level 1',
            },
            swift: 'Donate via SWIFT',
            crypto: 'Donate with cryptocurrencies',
          },
          donationsInside: {
            header: 'Money transfers inside Ukraine:',
            oschadbank: 'Account in Oschadbank',
            privatbank: 'Account in Privatbank',
            crypto: 'Donate with cryptocurrencies',
          },
          contacts: {
            header: 'Our Contacts',
            address: {
              header: 'Address:',
              text1: '32, Bohdana Hmelnytskoho St., office 41, Kyiv',
              text2: 'Level 1, entrance through the arch',
            },
            phone: {
              header: 'Phone:',
              tel1Caption: '+38 (044) 338-33-38',
              tel1Tel: '+380443383338',
              tel2Caption: '+38 (068) 500-88-00',
              tel2Tel: '+380685008800',
              workingHours: 'Mon.-Fri.: 10:00 - 19:00',
            },
          },
          website: {
            text: 'For more information, including financial reporting, go to: ',
          },
        },
      },
      civil: {
        header: 'Supporting Ukrainian civilians',
        eastSOS: {
          header: 'Charity organization «East-SOS».',
          usd: 'Donate in USD',
          eur: 'Donate in EUR',
          uah: 'Donate in UAH',
        },
        proliska: {
          header: 'Humanitarian Mission «Proliska».',
          anyBank: 'From any bank',
          swift: 'SWIFT',
        },
        voicesOfChildren: {
          header: 'The Voices of Children Foundation.',
          patreon: 'Support us on Patreon',
          crypto: 'Donate in crypto',
          uah: 'Bank transfer in UAH',
          usd: 'Bank transfer in USD',
          eur: 'Bank transfer in EUR',
          other: 'You can find other ways to donate on our website - ',
        },
      },
    },
    charts: {
      mainPageBtn: 'Home page',
      header: 'Dynamics of russia`s losses in Ukraine',
      rotateWarning: 'Please rotate your screen to the landscape mode or use device with screen wider than 300 px',
      chartMode: {
        header: 'How many lines to show on the chart:',
        showMultiple: 'Multiple lines at a time',
        showOne: 'Only one line at a time',
      },
    },
    screenshot: {
      header: 'Download infographic',
      downloadBtn: 'Download',
      noDownload: 'Please download infographic using other browser than Safari. For example, Google Chrome, Mozilla Firefox, Microsoft Edge etc.',
    },
    footer: {
      developedBy: 'Developed by the',
      fund: 'Come Back Alive Fund',
      team: 'team',
      design: 'Design of this page inspired by the similar reports of the',
      mfa: 'Ukrainian MFA',
      facebook: 'on Facebook',
      iconsFrom: 'Icons: ',
    },
    modal: {
      copied: 'Copied!',
      downloadingImg: 'Downloading infographic',
      errorDownloadingImg: 'Error downloading infographic',
    },
  },
  ua: {
    nav: {
      losses: 'Головна сторінка',
      donate: 'Як я можу допомогти?',
      charts: 'Графіки',
      screenshot: 'Зберегти інфографіку',
    },
    main: {
      header: {
        header: 'Втрати російських загарбників в Україні',
        updateDate: 'Дані актуальні станом на:',
        language: 'uk-Ua',
        numbersProvided: 'Джерело: ',
        genStaff: 'Генеральний Штаб ЗСУ',
        warning: 'Дані уточнюються. Підрахунок ускладнюється високою інтенсивністю бойових дій',
      },
      dates: {
        start: 'Початок повномасштабної агресії',
        language: 'uk-Ua',
        daysPassed: 'днів повномасштабної агресії росії',
        endOfWar: 'Кінець війни',
        today: 'Сьогодні',
      },
      losses: {
        personnel: 'військові',
        aircrafts: 'літаки',
        helicopters: 'гелікоптери',
        armoredVehicles: 'бойові броньовані машини',
        vehicles: 'автомобілі',
        tanks: 'танки',
        artillery: 'артилерійські системи',
        mlrs: 'реактивні системи залпового вогню',
        cisterns: 'цистерни з ПММ',
        antiAir: 'засоби ППО',
        uav: 'БПЛА оперативно-тактичного рівня',
        vessels: 'кораблі',
        specialVehicle: 'спеціальна техніка',
        srmb: 'ПУ ОТРК / ТРК',
      },
    },
    donate: {
      copyIconTitle: 'Копіювати текст',
      expandBtn: {
        open: 'Розгорнути',
        close: 'Згорнути',
      },
      military: {
        header: 'Допомога захисникам України',
        comeBackAlive: {
          orgName: 'Фонд "Повернись Живим"',
          donationsOutside: {
            header: 'Пожертви з-за меж України',
            fondy: {
              header: 'Пожертви за допомогою Fondy.eu',
              text: 'Fondy має найвищий рівень безпеки – PCI DSS Level 1',
            },
            swift: 'Пожертви за допомогою SWIFT',
            crypto: 'Пожертви у криптовалюті',
          },
          donationsInside: {
            header: 'Перекази по Україні',
            oschadbank: 'Рахунок в Ощадбанку',
            privatbank: 'Рахунок в Приватбанку',
            crypto: 'Допомога криптою',
          },
          contacts: {
            header: 'Наші контакти',
            address: {
              header: 'Адреса:',
              text1: 'вул. Богдана Хмельницького 32, оф. 41, Київ',
              text2: 'Поверх 1, вхід через арку',
            },
            phone: {
              header: 'Телефон:',
              tel1Caption: '+38 (044) 338-33-38',
              tel1Tel: '+380443383338',
              tel2Caption: '+38 (068) 500-88-00',
              tel2Tel: '+380685008800',
              workingHours: 'Пн-Пт: 10:00 - 19:00',
            },
          },
          website: {
            text: 'Дізнайтеся більше інформації на нашому сайті включно з фінансовими звітами - ',
          },
        },
      },
      civil: {
        header: 'Допомога цивільному населенню України',
        eastSOS: {
          header: 'Благодійний фонд «Восток-СОС»',
          usd: 'Пожертви у USD',
          eur: 'Пожертви у EUR',
          uah: 'Пожертви у UAH',
        },
        proliska: {
          header: 'Гуманітарна місія «Проліска»',
          anyBank: 'Підтримати з карти будь-якого банку',
          swift: 'SWIFT',
        },
        voicesOfChildren: {
          header: 'Благодійний фонд Голоси дітей',
          patreon: 'Підтримайте нас на Patreon',
          crypto: 'Для внесків у крипті',
          uah: 'Для внесків в UAH',
          usd: 'Для внесків в USD',
          eur: 'Для внесків в EUR',
          other: 'Інші способи оплати можете знайти на нашому сайті - ',
        },
      },
    },
    charts: {
      mainPageBtn: 'Головна сторінка',
      header: 'Динамика втрат росії в України',
      rotateWarning: 'Будь ласка, поверніть екран горизонтально або використовуйте пристрій з шириною екрану більше 300 px',
      chartMode: {
        header: 'Скільки ліній показувати на графіку:',
        showMultiple: 'Декілька ліній',
        showOne: 'Лише одну лінію',
      },
    },
    screenshot: {
      header: 'Завантажити інфографіку',
      downloadBtn: 'Завантажити',
      noDownload: 'Завантажте інфографіку, будь ласка, у іншому браузері ніж Safari. Наприклад Google Chrome, Mozilla Firefox, Microsoft Edge тощо.',
    },
    footer: {
      developedBy: 'Розроблено командою фонду',
      fund: 'Повернись Живим',
      team: '',
      design: 'Дизайн сторінки розроблений на основі відповідних звітів',
      mfa: 'Міністерства закордонних справ України',
      facebook: 'у фейсбуці',
      iconsFrom: 'Іконки взято з',
    },
    modal: {
      copied: 'Скопійовано!',
      downloadingImg: 'Завантажую інфографіку',
      errorDownloadingImg: 'Помилка завантаження інфографіки',
    },
  },
};
